export const Bio = {
  name: "Abhishek Kushwah",
  roles: ["Full Stack Developer", "React Native Developer", "Programmer"],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  github: "",
  resume: "",
  linkedin: "https://www.linkedin.com/in/abhishek-kushwah-23397a212/",
  twitter: "",
  insta: "",
  facebook: "",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image: "/reactjs.png",
      },
      {
        name: "Redux",
        image: "/redux.png",
      },
      {
        name: "Next Js",
        image: "/nextjs.png",
      },
      {
        name: "HTML",
        image: "/html.png",
      },
      {
        name: "CSS",
        image: "/css.png",
      },
      {
        name: "JavaScript",
        image: "/javascript.png",
      },
      {
        name: "jQuery",
        image: "/jquery.webp",
      },
      {
        name: "Bootstrap",
        image: "/bootstrap.png",
      },
      {
        name: "Material UI",
        image: "/materialui.png",
      },
      {
        name: "React Suite",
        image: "/rsuite.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "/nodejs.png",
      },
      {
        name: "Express Js",
        image: "/express.png",
      },
      {
        name: "PHP",
        image: "/php.png",
      },
      {
        name: "MySQL",
        image: "/mysql.png",
      },
      {
        name: "Postgresql",
        image: "/elephant.png",
      },
      {
        name: "Firebase",
        image: "/firebase.png",
      },
    ],
  },
  {
    title: "Android",
    skills: [
      {
        name: "React Native",
        image: "/reactjs.png",
      },
    ],
  },
  {
    title: "DevOps",
    skills: [
      {
        name: "Git",
        image: "/git.png",
      },
      {
        name: "GitHub",
        image: "/github.jpg",
      },
      {
        name: "Play Console",
        image: "/play-console-blue.png",
      },
      {
        name: "VPS Deployment",
        image: "/vps.png",
      },
      {
        name: "Nginx",
        image: "/nginx.png",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "/oriol.png",
    role: "Fullstack Software Developer",
    company: "Oriolinfotech Solutions Pvt. Ltd.",
    date: "Oct 2021 - Present",
    desc: [
      `1. 3 years of progressive technical and functional experience in Software Development Life Cycle(SDLC)
      that includes Requirement gathering, Analysis, Design, Development, Testing, Maintenance, En-
      hancement, and Support.`,
      `2. Led a team of 6 developers in the design, development, and deployment of web and mobile applications. Coordinated project planning, sprint meetings, and code reviews, ensuring timely delivery and quality standards. Developed backend services using NodeJs, PHP and frontend interfaces with ReactJs, NextJs, RefineJs, Jquery etc. Fostered a collaborative environment, managed version control with Git, and optimized application performance through efficient coding practices.`,
      `3. Orchestrated the implementation of a finance solution leveraging RefineJS, facilitating advanced salary disburse-ment to employees. Ensured accuracy and timeliness in transactions, leading to a 40% decrease in payroll processing errors and a 25% acceleration in disbursement speed.`,
      `4. Engaged with project managers, product owners, and stakeholders to prioritize over 30 features, swiftly resolve 50+ issues, and consistently deliver top-tier software solutions meeting deadlines and budgetary targets`,
    ],
    skills: [
      "HTML",
      "CSS",
      "JavaScript",
      "TypeScript",
      "NodeJs",
      "ExpressJs",
      "NextJs",
      "ReactJs",
      "Redux",
      "React Native",
      "PHP",
      "Mysql",
      "MongoDb",
      "Firebase",
      "RefineJs",
      "Codeigniter",
      "Git",
      "GitHub",
      "Postman",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "/mlb.jpg",
    school: "Govt. Jiwaji Rao H.S School",
    date: "Oct 2021 - Sep 2025",
    grade: "8.71 CGPA",
    desc: "I am currently pursuing a Bachelor's degree in Computer Science and Engineering at Kalinga Institute of Industrial Technology, Bhubaneswar. I have completed 4 semesters and have a CGPA of 8.71. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I am also a member of the Google Developers Student Club (GDSC) at KIIT, where I am learning and working on exciting projects with a team of talented developers.",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
  },
  {
    id: 1,
    img: "/mlb.jpg",
    school: "Methodist School, Dankuni",
    date: "Apr 2019 - Apr 2025",
    grade: "88.2%",
    desc: "I completed my class 12 high school education at Methodist School, Dankuni, where I studied Science with Computer Science.",
    degree: "ISC(XII), Science with Computer",
  },
];

export const projects = [
  {
    id: 0,
    title: "Getmii.in Administration Panel",
    description: `I orchestrated the development of a sophisticated e-commerce Content Management System (CMS) designed to efficiently oversee vendor management, sales, and product catalogs, including various categories and subcategories. This comprehensive system encompasses robust features for advertisement management, product stock, inventory tracking, billing, and financial analysis, including Profit and Loss statements. Additionally, the CMS seamlessly integrates a Point of Sale (POS) system to facilitate smooth operations for counter sales. Leveraging my expertise, I ensured the seamless integration of all modules, prioritizing user-friendly interfaces and efficient data management. My contributions culminated in a scalable solution that enhances productivity, streamlines operations, and fosters financial transparency for businesses.`,
    image: "/getmiisuperadmin.png",
    tags: [
      "ReactJs",
      "NodeJs",
      "ExpressJs",
      "Mysql",
      "Redux",
      "React-query",
      "Rsuite",
      "Nginx",
    ],
    category: "CMS",
    github: "",
    webapp: "https://admin.getmii.in",
  },
  {
    id: 1,
    title: "Getmii.in vendor webapp",
    description:
      "I directed the creation of a comprehensive full-stack project tailored for vendors within an e-commerce company, empowering them to efficiently manage various aspects of their business operations. This encompassed sales management, product stock, inventory tracking, and monitoring user activity. Additionally, the project included customization options for vendors to personalize the look and feel of their virtual shops, ensuring a unique and engaging experience for users accessing the platform via a React Native mobile application. Leveraging a robust technology stack, including Node.js for backend development, the project was designed to be scalable, secure, and seamlessly integrated with existing systems. My role involved overseeing the project's architecture, implementation, and optimization, resulting in a user-friendly and feature-rich solution that enhances vendors' capabilities and overall customer satisfaction",
    image: "/getmiiwebapp.jpg",
    tags: [
      "React Js",
      "NodeJs",
      "Redux",
      "Express Js",
      "React-query",
      "Bootstrap",
      "Rsuite",
    ],
    category: "web-app",
    github: "",
    webapp: "https://partner.getmii.in",
  },
  {
    id: 2,
    title: "Getmii: Online Shopping App",
    description:
      "I spearheaded the creation of an intuitive e-commerce mobile application built on React Native, facilitating users' seamless access to products from local vendors in their city. Leveraging modern libraries such as React Query, the app ensures efficient data fetching and management. Integration with Firebase for real-time database functionality and push notifications enhances user engagement and provides timely updates. The backend infrastructure was developed using Node.js, ensuring robustness and scalability. By prioritizing user experience and leveraging cutting-edge technologies, I contributed to the development of a dynamic platform that connects users with local vendors while offering a smooth and responsive shopping experience",
    image: "/getmiiapp.jpg",
    tags: [
      "React Native",
      "Node Js",
      "Mysql",
      "Firebase",
      "Express Js",
      "Redux",
      "React-query",
      "NodeMailer",
    ],
    category: "android",
    github: "",
    webapp: "https://play.google.com/store/apps/details?id=com.root.getmii",
  },
  // {
  //   id: 3,
  //   title: "My Menu Kart: Food Ordering App",
  //   description: `spearheaded the development of a robust food ordering application, utilizing React Native to ensure seamless user experiences across iOS and Android platforms. Collaborating closely with stakeholders, I managed a team of developers to deliver the project on schedule. Key achievements include integrating Node.js backend services for efficient data exchange, implementing Firebase for push notifications resulting in a 30% increase in user engagement, and integrating Google Maps API for live tracking of delivery personnel, reducing delivery times by 25%. Through performance optimization efforts, I achieved a 40% reduction in app loading times. My leadership skills were pivotal in providing mentorship and guidance to the team, ensuring adherence to best practices and timely project delivery`,
  //   image: "/mmk.jpg",
  //   tags: [
  //     "React Native",
  //     "Node Js",
  //     "Mysql",
  //     "Firebase",
  //     "Express Js",
  //     "Redux",
  //     "React-query",
  //     "NodeMailer",
  //   ],
  //   category: "android",
  //   github: "",
  //   webapp:
  //     "https://play.google.com/store/apps/details?id=com.mymenukart&hl=en_IN&gl=US",
  // },
  {
    id: 4,
    title: "Panic Button SOS System",
    description: `Engineered a SOS system for guards of UK-based security firm, enabling swift response and precise location tracking during emergencies.The Android application empowered guards to trigger an SOS alert. Upon activation, the system seamlessly alerted a monitoring team, displaying the guard’s location on a video wall featuring an Indian map with markers. The system highlighted the specific marker corresponding
      to the distressed guard, providing information in a popup window for immediate assistance`,
    image: "/noimage.jpg",
    tags: [
      "React Js",
      "NodeJs",
      "Redux",
      "Express Js",
      "React-query",
      "Bootstrap",
      "Rsuite",
      "Google Map",
      "Leaflet",
    ],
    category: "machine learning",
    github: "",
    webapp: "",
  },
  {
    id: 5,
    title: "Security Management System (SMS)",
    date: "Dec 2021 - Apr 2022",
    description: `Managed the development the front-end of a comprehensive Security Management System for a security company, facilitating efficient management of employee data encompassing
      attendance, leave, and resignation processes. Utilized ReactJS technology to ensure a dynamic user-friendly
      interface, enhancing accessibility and usability for administrators and employees alike.`,
    image: "/noimage.jpg",
    tags: [
      "HTML",
      "CSS",
      "Jquery",
      "Bootstrap",
      "MVC achitecture",
      "Javascript",
      "ReactJs",
      "Redux",
    ],
    category: "CMS",
    github: "",
    webapp: "",
  },
  {
    id: 6,
    title: "Advanced Salary Disbursement Project",
    date: "Jan 2021",
    description: `Directed the front-end development of a finance project using Refinejs and Next.js, augmented with RefineJS for advanced salary disbursement to employees. Implemented
      Casbin access control library for robust security measures and incorporated server-side rendering for enhanced performance and user experience`,
    image: "/noimage.jpg",
    tags: [
      "ReactJs",
      "RefineJs",
      "NextJs",
      "casbin",
      "SSR",
      "React-query",
      "redux",
    ],
    category: "CMS",
    github: "",
    webapp: "",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
